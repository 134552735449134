<template>
  <div>
    <b-embed
        type="iframe"
        __aspect="16by9"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%238e66ff&ctz=Europe%2FMoscow&mode=MONTH&showPrint=0&showCalendars=0&showTz=0&src=Y180aGw3am44ODczY25ndmNmZ3F0YXE3ZGhhc0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23795548"
        allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue'

export default {
  components: {
    BEmbed,
  },
}
</script>
